import React from 'react'

const CentPiggyBank: React.FC<React.SVGProps<SVGSVGElement>> = ({
  ...props
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_47_1189)">
        <path fillRule="evenodd" clipRule="evenodd" d="M10 11H16V13H10V11Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 2C12.4477 2 12 2.44772 12 3C12 3.55228 12.4477 4 13 4C13.5523 4 14 3.55228 14 3C14 2.44772 13.5523 2 13 2ZM10 3C10 1.34315 11.3431 0 13 0C14.6569 0 16 1.34315 16 3C16 4.65685 14.6569 6 13 6C11.3431 6 10 4.65685 10 3Z"
        />
        <path fillRule="evenodd" clipRule="evenodd" d="M11 20V24H9V20H11Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M17 20V24H15V20H17Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.62081 6.99997H16.5C20.6423 6.99997 24 10.3577 24 14.5C24 18.6423 20.6423 22 16.5 22H9.5C6.62109 22 4.12397 20.3773 2.86722 18H0V11H2.87249C3.34008 10.1151 3.97776 9.33374 4.74566 8.70195L3.7743 5.36802L4.67103 5.05563L5 5.99997C4.67103 5.05563 4.67155 5.05545 4.67208 5.05527L4.67319 5.05488L4.67562 5.05404L4.68128 5.05211L4.69574 5.04729C4.70677 5.04367 4.72056 5.03925 4.73697 5.0342C4.76977 5.0241 4.81323 5.01142 4.86626 4.99747C4.97201 4.96966 5.11759 4.93635 5.29394 4.90863C5.64306 4.85376 6.13355 4.81748 6.68381 4.90243C7.70432 5.05996 8.83846 5.62593 9.62081 6.99997ZM6.29438 6.86764C6.32228 6.87087 6.3504 6.87465 6.37869 6.87901C6.90598 6.96041 7.59889 7.25341 8.07887 8.38922L8.33696 8.99997H16.5C19.5377 8.99997 22 11.4623 22 14.5C22 17.5377 19.5377 20 16.5 20H9.5C7.2149 20 5.25313 18.6049 4.4229 16.6149L4.16634 16H2V13H4.17055L4.42748 12.386C4.8498 11.3769 5.55831 10.5192 6.45506 9.92078L7.06529 9.51358L6.29438 6.86764Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_47_1189">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CentPiggyBank
